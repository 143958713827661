<template>
  <div class="page-withdrawal">
    <div class="withdrawal-data">
      <div class="header">
        <span>可提现佣金(元)</span>
        <router-link class="header-link" to="/distribution/withdrawalLog"
          >提现记录</router-link
        >
      </div>
      <div class="content">
        <span>¥</span>
        <span class="content-value">{{ userInfo.price }}</span>
      </div>
    </div>
    <div class="withdrawal-form">
      <p class="title">提现金额</p>
      <div class="withdrawal-input">
        <span class="input-text">¥</span>
        <input v-model="value" class="input-value" type="text" maxlength="12" />
        <span class="input-btn" @click="handleInputBtnClick">全部提现</span>
      </div>
      <div class="withdrawal-btn" @click="handlewithdrawalBtnClick">
        申请提现
      </div>
      <div class="withdrawal-check">
        <van-checkbox
          v-model="checked"
          icon-size="4.267vw"
          checked-color="#FF712A"
        >
          <span>我同意</span>
          <router-link class="check-link" to="/distribution/about"
            >《提现协议》</router-link
          >
        </van-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  data() {
    return {
      checked: false,
      value: ''
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo
    })
  },
  methods: {
    ...mapMutations({ updateUserPrice: 'user/updateUserPrice' }),

    handleInputBtnClick() {
      this.value = `${this.userInfo.price}`;
    },

    handlewithdrawalBtnClick() {
      let price = Number(this.value);
      if (!price) return this.$toast('请输入提现金额');
      if (!this.checked) return this.$toast('请先阅读并同意提现协议');
      if (price > this.userInfo.price) {
        return this.$toast('提现金额不能大于可提现佣金');
      }
      this.$http('cash/apply', { price }, { dialog: false })
        .then(res => {
          this.value = '';
          this.updateUserPrice(Number(res));
          this.$toast.success('提交申请成功');
        })
        .catch(err => {
          try {
            let errData = JSON.parse(err.message);
            this.$dialog.alert({
              message: errData.message
            });
          } catch {
            let errMsg = err.message;
            this.$dialog.alert({
              message: errMsg
            });
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.withdrawal {
  &-data {
    padding: 45px 60px 0;
    width: 750px;
    height: 320px;
    background: linear-gradient(180deg, #ff925e 0%, #ff4019 100%);
    color: #fff;

    .header {
      @include flex-between-center;
      margin-bottom: 12px;
      font-size: 28px;

      &-link {
        font-size: 28px;
        color: #fff;
      }
    }

    .content {
      font-size: 38px;
      font-weight: 500;

      &-value {
        margin-left: 12px;
        font-size: 52px;
      }
    }
  }

  &-form {
    margin: -108px auto;
    padding: 40px 30px 0;
    width: 690px;
    height: 322px;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 0px 28px 0px rgba(153, 153, 153, 0.24);

    .title {
      margin-bottom: 9px;
      font-size: 28px;
    }
  }

  &-input {
    @include hairlines(bottom, #e1e1e1);
    @include align-end;

    .input {
      &-text {
        margin-bottom: 3px;
        font-size: 48px;
        color: #4d4d4d;
      }

      &-value {
        padding: 0 30px;
        width: 489px;
        font-size: 68px;
        font-weight: 600;
      }

      &-btn {
        margin-bottom: 10px;
        font-size: 28px;
        color: #3978e3;
      }
    }
  }

  &-check {
    position: absolute;
    top: 390px;
    left: 50%;
    transform: translateX(-50%);

    &::v-deep {
      .van-checkbox__label {
        margin-top: 2px;
      }
    }

    .check {
      &-link {
        display: inline;
        color: #4488ff;
      }
    }
  }

  &-btn {
    @include flex-center-center;
    position: absolute;
    bottom: -44px;
    left: 46px;
    width: 598px;
    height: 88px;
    border-radius: 44px;
    font-size: 30px;
    font-weight: 500;
    color: #fff;
    background: linear-gradient(180deg, #fea06a 0%, #fd4a26 100%);
  }
}
</style>
