<template>
  <!-- 直推数据 -->
  <div class="promote" v-if="initialized">
    <div class="promote-data">
      <div class="promote-data_title">生彩有道 · 我的客户</div>
      <div class="promote-data_container">
        <p class="data-title">
          <span>整体数据</span>
          <span class="data-time"
            >数据截至
            {{ promoteData.time | dateFormat('YYYY.MM.DD HH:mm:ss') }}</span
          >
        </p>
        <div class="data-items">
          <div class="data-item">
            <p class="data-value">{{ promoteData.browseTotal }}</p>
            <p>客户总数(个)</p>
          </div>
          <div class="data-item">
            <p class="data-value">{{ promoteData.payTotal }}</p>
            <p>我的收益(元)</p>
          </div>
        </div>
      </div>
    </div>
    <div class="promote-list">
      <van-tabs
        v-model="curTabIdx"
        class="promote-tabs"
        @change="handleTabsChange"
      >
        <van-tab title="普通订单"></van-tab>
        <van-tab title="服务订单"></van-tab>
      </van-tabs>
      <div class="promote-item" v-for="item in promoteData.list" :key="item.id">
        <div class="item-header">
          <img class="item-face" :src="item.face" v-if="item.face" />
          <app-icon
            class="item-face"
            name="touxiang"
            size="68"
            v-else
          ></app-icon>
          <p class="item-name">
            <span>{{ item.userName }}</span>
            <app-icon
              class="item-name_suffix"
              name="bangzhu"
              size="28"
            ></app-icon>
          </p>
          <p class="item-time">
            {{ item.time | dateFormat('YYYY.MM.DD HH:mm:ss') }}
          </p>
        </div>
        <p class="item-id">
          <span>交易单号：</span>
          <span>{{ item.orderCode }}</span>
        </p>
        <p class="item-info">
          <span>购买内容：</span>
          <span class="item-info_value">{{ item.content }}</span>
        </p>
        <p class="item-info">
          <span>支付金额：</span>
          <span class="item-info_value">¥ {{ item.price }}</span>
        </p>
        <p class="item-info">
          <span>所得佣金：</span>
          <span class="item-info_value">¥ {{ item.profit }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userId: '',
      curTabIdx: 0,
      promoteData: {
        list: [],
        time: 0,
        browseTotal: 0,
        payTotal: 0,
        profitTotal: 0
      },
      initialized: false
    };
  },
  methods: {
    getPromoteData() {
      this.$toast.loading({
        message: '加载中',
        forbidClick: true,
        duration: 0
      });
      Promise.all([
        this.getPromoteCountData({ loading: false, dialog: false }),
        this.getPromoteList({ loading: false, dialog: false })
      ])
        .then(() => {
          this.initialized = true;
        })
        .catch(err => {
          let errData = JSON.parse(err.message);
          this.$dialog.alert({
            message: `页面初始化失败，请联系管理员！\n错误信息：${errData.message}`
          });
        })
        .finally(() => {
          this.$toast.clear();
        });
    },

    getPromoteCountData(config = {}) {
      return this.$http('user/pushCount', {}, config).then(res => {
        let { promoteData } = this;
        promoteData.time = res.time * 1000;
        promoteData.browseTotal = res.clientCount;
        promoteData.payTotal = res.incomeCount;
      });
    },

    getPromoteList(config = {}) {
      return this.$http(
        'user/pushList',
        {
          type: this.curTabIdx ? 'service' : 'order',
          page: 1,
          pageSize: 999
        },
        config
      ).then(res => {
        this.promoteData.list = res.data.map(e => {
          return {
            id: e.id,
            userName: e.user.nickName,
            face: e.user.avatarUrl,
            time: e.payTime * 1000,
            orderCode: e.orderNo,
            price: Number(e.price) || 0,
            profit: Number(e.firstPrice) || 0,
            content: this.curTabIdx
              ? `生彩有道-${e.serviceName}会员`
              : `付款预测(排列五：第${e.no}期)`
          };
        });
      });
    },

    handleTabsChange() {
      this.getPromoteList();
    }
  },
  created() {
    this.getPromoteData();
  }
};
</script>

<style lang="scss" scoped>
.promote {
  &-data {
    margin-bottom: -35px;
    padding: 53px 30px 0;
    width: 100%;
    height: 486px;
    background: url(~image/distribution/promoteData/promote-data_bg.png) left
      top/contain no-repeat;

    &_bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, #ff977c 0%, #f12c3c 100%);
    }

    &_title {
      margin-bottom: 36px;
      font-size: 46px;
      font-weight: 600;
      color: #fff;
      text-shadow: 0px 3px 4px rgba(156, 0, 0, 0.5);
    }

    &_container {
      padding: 30px;
      width: 100%;
      height: 250px;
      border-radius: 16px;
      background: #fff;
      box-shadow: 0px 6px 22px 0px rgba(156, 0, 8, 0.5);

      .data {
        &-title {
          @include flex-between-center;
          @include hairlines(bottom, #ccc);
          padding-bottom: 30px;
          width: 100%;
          font-size: 32px;
          font-weight: 500;
        }

        &-time {
          font-size: 22px;
          font-weight: 400;
          color: #999;
        }

        &-items {
          display: flex;
          padding: 20px 0;
        }

        &-item {
          flex: 1;
          text-align: center;
          color: #808080;
        }

        &-value {
          margin-bottom: 4px;
          font-size: 42px;
          color: #f70e11;
        }
      }
    }
  }

  &-list {
    padding: 0 30px 50px;
    width: 100%;
    border-radius: 32px 32px 0px 0px;
    background: #fff;
  }

  &-tabs {
    margin: 0 auto 26px;
    width: 376px;

    &::v-deep {
      .van-tabs__line {
        width: 120px;
        background: linear-gradient(90deg, #fea06a 0%, #fd4a26 100%);
      }

      .van-tab {
        font-size: 32px;
        font-weight: 500;
        color: #aaa;

        &--active {
          color: #ff5225;
        }
      }
    }
  }

  &-item {
    margin-bottom: 40px;
    padding: 30px 30px 40px;
    width: 690px;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 0px 28px 0px rgba(153, 153, 153, 0.24);

    &:last-of-type {
      margin-bottom: 0;
    }

    .item {
      &-header {
        @include align-center;
        @include hairlines(bottom, #ccc);
        margin-bottom: 30px;
        padding-bottom: 30px;
      }

      &-face {
        width: 68px;
        height: 68px;
        border-radius: 50%;
        margin-right: 30px;
      }

      &-name {
        width: 311px;
        font-size: 32px;
        font-weight: 500;
        &_suffix {
          margin-left: 30px;
        }
      }

      &-time {
        font-size: 22px;
        color: #999;
      }

      &-info {
        @include flex-between-center;
        margin-bottom: 30px;
        font-size: 28px;
        color: #999;

        &:last-of-type {
          margin-bottom: 0;
        }

        &_value {
          color: #4d4d4d;
        }
      }

      &-id {
        @extend .item-info;
        color: #333;
      }
    }
  }
}
</style>
