<template>
  <div class="page-withdrawalLog">
    <van-list
      class="log-list"
      v-model="listLoading"
      :error.sync="listError"
      :finished="listFinished"
      error-text="请求失败，点击重新加载"
      finished-text="没有更多数据了"
      @load="handleListLoad"
    >
      <div class="log-item" v-for="(item, key) in logList" :key="key">
        <p class="item-title" @click="handleLogMonthClick(key)">
          {{ key }}
        </p>
        <div class="item-wrap" v-for="(data, i) in item" :key="i">
          <div class="item-data">
            <p class="item-label">{{ data.typeLabel }}</p>
            <p class="item-price">{{ data.price }}元</p>
            <div class="item-time">
              <p>
                {{ (data.updateTime * 1000) | dateFormat('YYYY-MM-DD hh:mm') }}
              </p>
              <p class="item-status">
                <span
                  :class="`item-status${data.status === 2 ? '_success' : ''}`"
                  >{{ data.statusLabel }}</span
                >
                <span v-if="data.explain">：{{ data.explain }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </van-list>
    <van-popup v-model="datetimePickerShow" position="bottom" round>
      <van-datetime-picker
        v-model="currentDate"
        type="year-month"
        title="选择年月"
        @cancel="datetimePickerShow = false"
        @confirm="handleDatetimePickerConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listLoading: false,
      listError: false,
      listFinished: false,
      listPage: 1,
      listLastPage: 0,
      currentDate: '',
      datetimePickerShow: false,
      logList: {}
    };
  },
  methods: {
    handleListLoad(month = '') {
      if (month) {
        this.listPage = 1;
        this.listLastPage = 0;
        this.listLoading = true;
        this.logList = {
          [month]: []
        };
      }
      if (this.listLastPage && this.listPage > this.listLastPage) return;
      this.$http(
        'cash/index',
        { page: this.listPage, pageSize: 30, month },
        { loading: false }
      )
        .then(res => {
          this.setLogList(res.data);
          this.listPage += 1;
          this.listLastPage = res.lastPage;
          this.listFinished = res.currentPage === res.lastPage;
        })
        .catch(err => {
          this.listError = true;
          throw new Error(err);
        })
        .finally(() => (this.listLoading = false));
    },

    setLogList(data) {
      let obj = this.logList;
      data.forEach(e => {
        let key = this.$dayjs(e.createTime * 1000).format('YYYY-MM');
        obj[key] = obj[key] ? [...obj[key], e] : [e];
      });
    },

    handleLogMonthClick(val) {
      this.currentDate = this.$dayjs(val).toDate();
      this.datetimePickerShow = true;
    },

    handleDatetimePickerConfirm(val) {
      this.datetimePickerShow = false;
      this.handleListLoad(this.$dayjs(val).format('YYYY-MM'));
    }
  }
};
</script>

<style lang="scss" scoped>
.log {
  &-item {
    .item {
      &-title {
        @include arrow(16px, bottom, #888);
        @include align-center;
        z-index: 1;
        position: sticky;
        top: 0;
        left: 0;
        padding: 0 30px;
        width: 100%;
        height: 100px;
        line-height: 100px;
        font-size: 28px;
        font-weight: 500;
        color: #191919;
        background: #f5f5f5;

        &::after {
          margin: -12px 15px 0;
        }
      }

      &-wrap {
        padding: 0 30px;
        width: 100%;
        height: 106px;
        background: #fff;
      }

      &-data {
        @include flex-between-center;
        @include hairlines(bottom, #ececec);
        height: 100%;
        color: #848484;
      }

      &-label {
        width: 200px;
      }

      &-price {
        width: 200px;
        font-weight: 500;
        text-align: center;
        color: #191919;
      }

      &-time {
        @include align-end;
        justify-content: center;
        flex-direction: column;
        flex: 1;
      }

      &-status {
        margin-top: 10px;
        text-align: right;

        &_success {
          color: #07ba2c;
        }
      }
    }
  }
}
</style>
