export default {
  methods: {
    createImgDom(imgPath) {
      return new Promise((resolve, reject) => {
        let img = document.createElement('img');
        img.src = imgPath;
        img.onload = () => {
          this.convertImageToCanvas(img);
        };
      });
    },

    convertImageToCanvas(image) {
      let canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      canvas.getContext('2d').drawImage(image, 0, 0);
      return canvas;
    },

    convertCanvasToImage(canvas) {
      let image = new Image();
      image.src = canvas.toDataURL('image/png');
      return image;
    }
  }
};
