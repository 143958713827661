<template>
  <div class="module-shareDistribution" v-show="show">
    <img class="share-img" v-if="imgSrc" :src="imgSrc" />
    <p class="share-explain">
      由于微信官方的原因，分享页面功能在部分安卓机上存在分享失败的问题，为确保分销商正常发展团队，因此<span
        class="share-explain_red"
        >建议安卓用户</span
      >使用复制链接的方式进行分享。
    </p>
    <div class="share-tip">长按转发图片</div>
  </div>
</template>

<script>
import mixin from '../mixin';
import bgImg from 'image/distribution/distributionShare/bg_distribution.jpg';
import { mapState } from 'vuex';

export default {
  mixins: [mixin],
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      shareUrl: '',
      distributionData: null,
      imgSrc: ''
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo
    })
  },
  watch: {
    show(val) {
      if (!val) return;
      this.handleShowChange();
    }
  },
  methods: {
    getDistributionData() {
      return this.$http('user/superiorShare', {}, { loading: false }).then(
        res => {
          this.distributionData = res;
        }
      );
    },

    setDistributionShare() {
      const { WX_SHARE_URL } = this.$config;
      let shareData = {
        name: this.userInfo.name,
        face: this.userInfo.face,
        day: this.distributionData.day,
        number: this.distributionData.number,
        totalPrice: this.distributionData.totalPrice
      };
      let shareUrl = `${WX_SHARE_URL}distribution/share`;
      let parentCode = sessionStorage.getItem('parentCode') || '';
      shareUrl += `?shareData=${encodeURIComponent(JSON.stringify(shareData))}`;
      shareUrl += parentCode ? `&parentCode=${parentCode}` : '';
      this.shareUrl = shareUrl;
      this.imgSrc = `https://api.bornfortune.com/api/superior/poster?type=superior&url=${encodeURIComponent(
        shareUrl
      )}&token=${localStorage.getItem('token') || ''}`;

      return this.$wx.initShare({
        title: '注册邀请',
        desc: `${this.userInfo.name}邀请您成为分销商`,
        link: shareUrl,
        imgUrl: 'https://api.bornfortune.com/resource/image/logo.png'
      });
    },

    async handleShowChange() {
      try {
        this.$toast.loading({});
        await this.getDistributionData();
        await this.setDistributionShare();
      } finally {
        this.$toast.clear();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.module-shareDistribution {
  padding: 0 0 50px;
}

.share {
  &-img {
    margin-bottom: 20px;
    width: 100%;
  }

  &-explain {
    margin-bottom: 44px;
    padding: 0 8px;
    font-size: 24px;
    text-indent: 2em;
    color: #979797;

    &_red {
      color: #ff1f1f;
    }
  }

  &-tip {
    margin: 0 auto;
    padding: 0 0 11px;
    width: 360px;
    border-bottom: 4px solid #ff6751;
    border-radius: 3px;
    text-align: center;
    font-size: 42px;
    font-weight: 500;
    color: #ff4c45;
  }
}
</style>
