<template>
  <div class="module-shareUser" v-show="show">
    <img class="share-img" :src="imgSrc" />
    <p class="share-explain">
      由于微信官方的原因，分享页面功能在部分安卓机上存在分享失败的问题，为确保您的分销收益，因此<span
        class="share-explain_red"
        >建议安卓用户</span
      >使用长按转发图片的方式进行分享。
    </p>
    <div class="share-tip">长按转发图片</div>
  </div>
</template>

<script>
import mixin from '../mixin';
import bgImg from 'image/distribution/distributionShare/bg_user.jpg';

export default {
  mixins: [mixin],
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      imgSrc: `https://api.bornfortune.com/api/superior/poster?type=user&token=${
        localStorage.getItem('token') || ''
      }`
    };
  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        if (!val) return;
        this.handleShowChange();
      }
    }
  },
  methods: {
    async handleShowChange() {
      this.$toast.loading({});
      this.$wx.initShare().finally(() => {
        this.$toast.clear();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.module-shareUser {
  padding: 0 0 50px;
}

.share {
  &-img {
    margin-bottom: 20px;
    width: 100%;
  }

  &-explain {
    margin-bottom: 44px;
    padding: 0 8px;
    font-size: 24px;
    text-indent: 2em;
    color: #979797;

    &_red {
      color: #ff1f1f;
    }
  }

  &-tip {
    margin: 0 auto;
    padding: 0 0 11px;
    width: 360px;
    border-bottom: 4px solid #ff6751;
    border-radius: 3px;
    text-align: center;
    font-size: 42px;
    font-weight: 500;
    color: #ff4c45;
  }
}
</style>
