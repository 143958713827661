<template>
  <!-- 注册分销商 -->
  <div class="register">
    <div class="register-logo"></div>
    <h2 class="register-title">已登录，请完成验证</h2>
    <p class="register-text">
      我们需要通过手机号码来验证您的身份。我们将通过短信给您发送验证。
    </p>
    <div class="verify">
      <div class="verify-item">
        <app-icon class="verify-icon" name="bangzhu" size="42"></app-icon>
        <div class="verify-value_phone">
          <input
            class="verify-input"
            type="text"
            maxlength="13"
            placeholder="请输入手机号码"
            inputmode="numeric"
          />
          <app-icon
            class="verify-input_suffix"
            name="bangzhu"
            size="31"
          ></app-icon>
        </div>
        <p class="verify-error">请输入正确的手机号码</p>
      </div>
      <div class="verify-item">
        <app-icon class="verify-icon" name="bangzhu" size="42"></app-icon>
        <div class="verify-value_code">
          <input
            class="verify-input"
            type="text"
            maxlength="6"
            placeholder="请输入验证码"
            inputmode="numeric"
          />
        </div>
        <div class="verify-btn">发送验证码</div>
        <p class="verify-error">请输入6位验证码</p>
      </div>
    </div>
    <div class="register-btn">提交</div>
    <van-checkbox
      class="register-agreement"
      v-model="checked"
      checked-color="#FD4A26"
      icon-size="11.5"
    >
      <span>我同意</span>
      <span class="register-agreement_link">《分销商经营规范》</span>
    </van-checkbox>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checked: true
    };
  }
};
</script>

<style lang="scss" scoped>
.register {
  padding: 104px 30px 0;

  &-logo {
    margin: 0 auto 50px;
    width: 140px;
    height: 140px;
    background: #ffffff;
    box-shadow: 0px 0px 28px 0px rgba(153, 153, 153, 0.24);
    border-radius: 32px;
  }

  &-title {
    margin: 0 auto 20px;
    text-align: center;
    font-size: 42px;
    font-weight: 500;
  }

  &-text {
    margin: 0 auto 68px;
    width: 576px;
    text-align: center;
    font-size: 26px;
    color: #999;
    line-height: 44px;
  }

  &-btn {
    @include flex-center-center;
    margin: 100px auto 30px;
    width: 598px;
    height: 88px;
    border-radius: 44px;
    font-size: 30px;
    font-weight: 500;
    color: #fff;
    background: linear-gradient(180deg, #fea06a 0%, #fd4a26 100%);
    box-shadow: 0px 4px 16px 0px rgba(255, 40, 0, 0.3);
  }

  &-agreement {
    justify-content: center;
    ::v-deep.van-checkbox__label {
      color: #666;
    }

    &_link {
      color: #4488ff;
    }
  }
}

.verify {
  width: 100%;

  &-item {
    @include align-center;
    margin-bottom: 58px;
  }

  &-icon {
    margin-right: 12px;
  }

  &-value {
    @include hairlines(bottom, #e0e0e0);
    flex: 1 1 auto;

    &_phone {
      @extend .verify-value;
      padding-right: 50px;
    }

    &_code {
      @extend .verify-value;
      margin-right: 30px;
    }
  }

  &-input {
    width: 100%;
    height: 68px;
    line-height: 68px;
    font-size: 28px;

    &_suffix {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }
  }

  &-error {
    position: absolute;
    left: 64px;
    bottom: -52px;
    color: #fd4a26;
  }

  &-btn {
    @include flex-center-center;
    width: 210px;
    height: 68px;
    border-radius: 8px;
    font-size: 28px;
    color: #fe7e4d;
    background: rgba(255, 169, 79, 0.15);
  }
}
</style>
