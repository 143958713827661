<template>
  <div class="page-distributionShare">
    <div class="share-tabs">
      <div
        :class="`share-tab${curTab === 1 ? '_active' : ''}`"
        @click="changeTab(1)"
      >
        分享用户
      </div>
      <div
        :class="`share-tab${curTab === 2 ? '_active' : ''}`"
        @click="changeTab(2)"
      >
        分享分销商
      </div>
    </div>
    <div class="share-container">
      <share-user :show="curTab === 1"></share-user>
      <share-distribution :show="curTab === 2"></share-distribution>
    </div>
  </div>
</template>

<script>
import shareUser from './components/shareUser.vue';
import shareDistribution from './components/shareDistribution.vue';

export default {
  components: {
    shareUser,
    shareDistribution
  },
  data() {
    return {
      curTab: 1
    };
  },
  methods: {
    changeTab(val = 1) {
      this.curTab = val;
    }
  }
};
</script>

<style lang="scss" scoped>
.page-distributionShare {
  padding: 158px 0 0;
}

.share {
  &-tabs {
    @include flex-between-center;
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    padding: 30px;
    width: 100%;
    background: #fff;
  }

  &-tab {
    @include flex-center-center;
    width: 328px;
    height: 98px;
    border: #ffbaaa 2px solid;
    border-radius: 16px;
    font-size: 32px;
    color: #8a8a8a;
    background: #fff;

    &_active {
      @extend .share-tab;
      border-color: none;
      color: #fffbeb;
      background: #fb9938 linear-gradient(90deg, #ff7b5a 0%, #ff182f 100%);
    }
  }

  &-container {
    padding: 0 30px;
  }
}
</style>
